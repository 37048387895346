// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CongratsDiscount_title__13K1J {\n  margin-bottom: 32px;\n}\n\n.CongratsDiscount_description___pYJM {\n  margin-bottom: 40px;\n}\n\n.CongratsDiscount_special__3BlKx {\n  text-align: center;\n  margin-bottom: 40px;\n}\n\n@media only screen and (max-width: 432px) {\n  .CongratsDiscount_customContainerMobile__3PwaS {\n    padding-top: 16px;\n  }\n\n  .CongratsDiscount_title__13K1J {\n    margin-bottom: 40px;\n  }\n\n  .CongratsDiscount_special__3BlKx {\n    margin-bottom: 40px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/CongratsDiscount/CongratsDiscount.module.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;AADJ;;AAIA;EACI,mBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,mBAAA;AADJ;;AAIA;EACI;IACI,iBAAA;EADN;;EAIE;IACI,mBAAA;EADN;;EAIE;IACI,mBAAA;EADN;AACF","sourcesContent":["@import '../../assets/scss/variables';\n\n.title {\n    margin-bottom: #{$default-spacing-unit * 4};\n}\n\n.description {\n    margin-bottom: #{$default-spacing-unit * 5};\n}\n\n.special {\n    text-align: center;\n    margin-bottom: #{$default-spacing-unit * 5};\n}\n\n@media only screen and (max-width: $mobile-breakpoint) {\n    .customContainerMobile {\n        padding-top: #{$default-spacing-unit * 2};\n    }\n\n    .title {\n        margin-bottom: #{$default-spacing-unit * 5};\n    }\n\n    .special {\n        margin-bottom: #{$default-spacing-unit * 5};\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "CongratsDiscount_title__13K1J",
	"description": "CongratsDiscount_description___pYJM",
	"special": "CongratsDiscount_special__3BlKx",
	"customContainerMobile": "CongratsDiscount_customContainerMobile__3PwaS"
};
export default ___CSS_LOADER_EXPORT___;
