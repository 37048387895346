import SnackbarService from './snackbar';

/**
 *
 *  Fallback validation: https://grapefruitdigital.atlassian.net/browse/PTQR-56
 *
 *  Caz normal: accessory_code 14 caractere.
 *  Fallback: device_code (12) + accessory_id (4)
 *
 * @returns true if the fallback validation is on, false otherwise
 */
export const isFallbackValidationOn = () => process.env.REACT_APP_FALLBACK_VALIDATION === 'true';

export const formErrorHandler = (errors, setError) => {
    for (const error of Object.keys(errors)) {
        const message = errors[error][0];

        if (error === 'general') {
            if (message.toLowerCase().includes('dispozitivului')) {
                setError('accessory_code', {
                    type: 'api_error',
                    message: message,
                });
                continue;
            }

            if (message.toLowerCase().includes('kit')) {
                setError('accessory_code', {
                    type: 'api_error',
                    message: message,
                });
                continue;
            }

            if (message.toLowerCase().includes('incearca')) {
                setError('accessory_code', {
                    type: 'api_error',
                    message: message,
                });
                continue;
            }

            if (message.toLowerCase().includes('dispozitiv')) {
                setError('device_code', {
                    type: 'api_error',
                    message: message,
                });
                continue;
            }

            if (message.toLowerCase().includes('magazin')) {
                setError('pos_code', {
                    type: 'api_error',
                    message: message,
                });
                continue;
            }

            SnackbarService.toast(message);
            continue;
        }

        setError(error, {
            type: 'api_error',
            message,
        });
    }
};

export const isInMaintenance = () => {
    const now = new Date();
    const searchParams = new URL(window.location.href).searchParams;
    
    //testing purpose
    const startParam = searchParams.get('maintenanceStart');
    const endParam = searchParams.get('maintenanceEnd');
    //testing purpose end

    const maintenanceStart = startParam ? new Date(startParam) : new Date('2024-04-03T07:00:00');
    const maintenanceEnd = endParam ? new Date(endParam) : new Date('2025-04-03T09:30:00');
    return now >= maintenanceStart && now <= maintenanceEnd;
};

export const handleAmountOfReward = (deviceType) => {
    const isIluma = deviceType?.toLowerCase().includes('iluma');

    if (isIluma) {
        return 2;
    }
    return 3;
};
