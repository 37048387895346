// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Steps_container__2_l-I {\n  width: 100vw;\n  min-height: 100%;\n  position: relative;\n  top: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://src/containers/Steps/Steps.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,MAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;AACJ","sourcesContent":[".container {\n    width: 100vw;\n    min-height: 100%;\n    position: relative;\n    top: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Steps_container__2_l-I"
};
export default ___CSS_LOADER_EXPORT___;
