import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Typography, Fade } from '@mui/material';

import { ReactComponent as IqosSvg } from '../../assets/images/iqos.svg';
import { ReactComponent as SolidSvg } from '../../assets/images/solid.svg';

import { PRODUCT_TYPES } from '../../helpers/config';
import { setProductType } from '../../redux/form';

import styles from './ProductSelection.module.scss';

export const ProductSelection = () => {
    const dispatch = useDispatch();

    const onClick = (productType) => {
        dispatch(setProductType(productType));
    };

    return (
        <Fade in>
            <Box className={styles.wrapper}>
                <Box className={styles.copy}>
                    <Typography variant='body'>Pentru a merge mai departe, alege produsul pe care l-ai achizitionat</Typography>
                </Box>
                <Box className={styles.option} onClick={() => onClick(PRODUCT_TYPES.iqos)}>
                    <IqosSvg />
                </Box>
                <Box className={styles.option} onClick={() => onClick(PRODUCT_TYPES.lil)}>
                    <SolidSvg />
                </Box>
            </Box>
        </Fade>
    );
};
