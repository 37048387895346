// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivateAccount_copy__HGDTe {\n  margin-bottom: 40px;\n}\n.ActivateAccount_copy__HGDTe > p {\n  margin-bottom: 16px;\n}\n\n@media only screen and (max-width: 432px) {\n  .ActivateAccount_customContainerMobile__3BpKE {\n    padding: 16px;\n  }\n\n  .ActivateAccount_copy__HGDTe {\n    margin-bottom: 40px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/ActivateAccount/ActivateAccount.module.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;AADJ;AAGI;EACI,mBAAA;AADR;;AAKA;EACI;IACI,aAAA;EAFN;;EAKE;IACI,mBAAA;EAFN;AACF","sourcesContent":["@import '../../assets/scss/variables';\n\n.copy {\n    margin-bottom: #{$default-spacing-unit * 5};\n\n    > p {\n        margin-bottom: #{$default-spacing-unit * 2};\n    }\n}\n\n@media only screen and (max-width: $mobile-breakpoint) {\n    .customContainerMobile {\n        padding: #{$default-spacing-unit * 2};\n    }\n\n    .copy {\n        margin-bottom: #{$default-spacing-unit * 5};\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy": "ActivateAccount_copy__HGDTe",
	"customContainerMobile": "ActivateAccount_customContainerMobile__3BpKE"
};
export default ___CSS_LOADER_EXPORT___;
