// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShopListContainer_noParent__36WD9 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://src/containers/ShopListContainer/ShopListContainer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACJ","sourcesContent":[".noParent {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noParent": "ShopListContainer_noParent__36WD9"
};
export default ___CSS_LOADER_EXPORT___;
