/* eslint-disable */
import * as yup from 'yup';
import { Box } from '@mui/system';
import classnames from 'classnames';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Fade, Dialog, DialogTitle, IconButton, DialogContent, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller, useFormState } from 'react-hook-form';

import { checkEmail } from '../../redux/form';
import { APP_CONFIG } from '../../helpers/config';

import styles from './CheckEmail.module.scss';
import { FormHelperDevice } from '../FormHelperDevice';

const isDev = process.env.NODE_ENV === 'development';

const schema = yup
    .object({
        email: yup
            .string()
            .trim()
            .email(APP_CONFIG.validationMessages.invalidEmailFormat)
            .max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max())
            .test('blacklist-emails', APP_CONFIG.validationMessages.invalidEmailFormat, function (value) {
                if (isDev) {
                    return true;
                }
                return APP_CONFIG.regexBlacklistEmails.test(value);
            })
            .required(APP_CONFIG.validationMessages.required),
        confirm_email: yup
            .string()
            .trim()
            .email(APP_CONFIG.validationMessages.invalidEmailFormat)
            .max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max())
            .required(APP_CONFIG.validationMessages.required)
            .test('emailConfirmation', APP_CONFIG.validationMessages.emailDontMatch, function (value) {
                return value !== undefined ? value.toLowerCase() === this.parent.email.toLowerCase() : false;
            }),
    })
    .required();

export const CheckEmail = () => {
    const dispatch = useDispatch();
    const { checkEmailResult, phone } = useSelector((state) => state.form);

    const [loading, setLoading] = useState(false);
    const [warningModalOpen, setWarningModal] = useState(false);

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { dirtyFields } = useFormState({
        control,
    });

    useEffect(() => {
        if (checkEmailResult.email) {
            setValue('email', checkEmailResult.email, { shouldDirty: true });
        }
    }, [checkEmailResult, setValue]);

    const onSubmit = (data) => {
        setLoading(true);

        const payload = {
            email: data.email.toLowerCase(),
            confirm_email: data.confirm_email.toLowerCase(),
            phone,
        };

        dispatch(checkEmail(payload)).then((response) => {
            if (response?.payload?.hasOwnProperty('isPhoneValidated') && !response?.payload.isPhoneValidated) {
                setLoading(false);
                setWarningModal(true);
                return;
            }

            if (response?.payload.status === 422) {
                setLoading(false);
                setError('email', {
                    type: 'api_error',
                    message: response.payload.data?.errors?.email?.[0],
                });
                return;
            }
        });
    };

    const handleChange = (e) => {
        e.preventDefault();
    };

    // Temporary approach requested by the client until they fix their business logic.
    // return (
    //     <Fade in>
    //         <Box className={classnames('container', styles.customContainerMobile)}>
    //             <Box className={styles.copy}>
    //                 <Typography variant='body'>
    //                     Ne pare rău, dar momentan înregistrarea online nu este disponibilă.
    //                 </Typography>
    //             </Box>
    //             <Box className={styles.copy} sx={{ marginBottom: 2 }}>
    //                 <Typography variant='body'>
    //                     Te rugăm trimite prin SMS gratuit la{' '}
    //                     <a className={styles.sms} href='sms:3768'>
    //                         3768
    //                     </a>{' '}
    //                     <Typography variant='bold'>codul unic al magazinului</Typography> înscris pe kit-ul
    //                     achiziționat, iar un consultant te va apela în maximum 24 de ore pentru înregistrarea
    //                     dispozitivului și pentru a beneficia de cele 3 pachete de rezerve gratuite sau de un discount în
    //                     contul tău din <Link href='https://www.iqos.ro'>www.iqos.ro</Link>.
    //                 </Typography>
    //             </Box>
    //             <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //                 <FormHelperDevice
    //                     type={APP_CONFIG.shopCodeId}
    //                     id='cod-magazin'
    //                     copy={'Unde găsesc codul magazinului?'}
    //                 />
    //             </Box>
    //         </Box>
    //     </Fade>
    // );

    return (
        <>
            <Fade in>
                <Box className={classnames('container', styles.customContainerMobile)}>
                    <Box className={styles.copy}>
                        <Typography variant='body'>
                            Pentru înregistrarea dispozitivului introduceți adresa de e-mail mai jos:
                        </Typography>
                    </Box>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box className={styles.email}>
                            <Controller
                                name='email'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={field.value.toLocaleLowerCase()}
                                        required
                                        error={!!errors.email}
                                        helperText={errors?.email?.message}
                                        id='outlined-error'
                                        label='Email'
                                        fullWidth
                                        onCut={handleChange}
                                        onCopy={handleChange}
                                        onPaste={handleChange}
                                        onSelect={handleChange}
                                    />
                                )}
                                defaultValue={''}
                            />
                        </Box>

                        <Box className={styles.email}>
                            <Controller
                                name='confirm_email'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        value={field.value.toLocaleLowerCase()}
                                        error={!!errors.confirm_email}
                                        helperText={errors?.confirm_email?.message}
                                        id='outlined-error'
                                        label='Confirmare e-mail'
                                        fullWidth
                                        onCut={handleChange}
                                        onCopy={handleChange}
                                        onPaste={handleChange}
                                    />
                                )}
                                defaultValue={''}
                            />
                        </Box>

                        <Box className={styles.nextButton}>
                            <LoadingButton
                                variant='contained'
                                type='submit'
                                loading={loading}
                                disabled={Object.keys(dirtyFields).length !== 2}
                            >
                                Pasul următor
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </Fade>
            <Dialog open={warningModalOpen} className={styles.modalBody}>
                <DialogTitle>
                    <IconButton
                        aria-label='close'
                        onClick={() => setWarningModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#000',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant='body' component='p' sx={{ margin: '2rem 0' }}>
                            Numărul de telefon este validat pe o altă adresă de e-mail decât cea introdusă. Vă rugam să
                            introduceți adresa de e-mail asociată numărului de telefon din contul dvs. IQOS.
                        </Typography>
                        <Typography variant='body' component='p' sx={{ margin: '1rem 0' }}>
                            În cazul în care necesitați asistentă, vă rugam să ne contactați la 0800.030.333*.
                        </Typography>
                        <Typography variant='bold' component='p' sx={{ margin: '2rem 0' }}>
                            *de luni până vineri, orele 08:00-20:00, de sâmbăta până duminică orele 10:00-18:00
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
/* eslint-enable */
