import { Box } from '@mui/system';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, Fade, Button, Link } from '@mui/material';

import { APP_CONFIG } from '../../helpers/config';
import { resetState, setStepId } from '../../redux/form';

import styles from './WarningLimitReached.module.scss';

export const WarningLimitReached = () => {
    const dispatch = useDispatch();

    const restart = () => {
        dispatch(resetState());
    };

    const next = () => dispatch(setStepId(APP_CONFIG.steps.WELCOME_LAU));

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copy}>
                    <Typography variant='body'>
                        A fost atins numărul maxim de oferte (pachete gratuite/ Discount pe{' '}
                        <Link href='https://www.iqos.ro'>www.iqos.ro</Link>) de care poți beneficia. Apasă butonul{' '}
                        Continuă pentru a <Typography variant='bold'>finaliza</Typography> înregistrarea și a beneficia
                        de <Typography variant='bold'>Garanția</Typography> pentru KIT-ul achiziționat.
                    </Typography>
                </Box>

                <Button variant='contained' onClick={next} fullWidth className={styles.nextButton}>
                    Continuă
                </Button>

                <Button variant='contained' onClick={restart} fullWidth>
                    Spre ecranul de început
                </Button>
            </Box>
        </Fade>
    );
};
