// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LimitReached_copy__2hFlP {\n  margin-bottom: 40px;\n}\n\n@media only screen and (max-width: 432px) {\n  .LimitReached_customContainerMobile__21eOT {\n    padding-top: 32px;\n  }\n\n  .LimitReached_copy__2hFlP {\n    margin-bottom: 40px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/LimitReached/LimitReached.module.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;AADJ;;AAIA;EACI;IACI,iBAAA;EADN;;EAIE;IACI,mBAAA;EADN;AACF","sourcesContent":["@import '../../assets/scss/variables';\n\n.copy {\n    margin-bottom: #{$default-spacing-unit * 5};\n}\n\n@media only screen and (max-width: $mobile-breakpoint) {\n    .customContainerMobile {\n        padding-top: #{$default-spacing-unit * 4};\n    }\n\n    .copy {\n        margin-bottom: #{$default-spacing-unit * 5};\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy": "LimitReached_copy__2hFlP",
	"customContainerMobile": "LimitReached_customContainerMobile__21eOT"
};
export default ___CSS_LOADER_EXPORT___;
