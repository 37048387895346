import classnames from 'classnames';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Fade, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setStepId } from '../../redux/form';
import { APP_CONFIG, PRODUCT_TYPES } from '../../helpers/config';

import styles from './CongratsShop.module.scss';
import { handleAmountOfReward } from '../../helpers/general';

export const CongratsShop = () => {
    const dispatch = useDispatch();

    const { productType, checkEmailResult, registerLAUResult } = useSelector((state) => state.form);

    const gotToShopList = () => dispatch(setStepId(APP_CONFIG.steps.SHOP_LIST));

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>

                <Box className={styles.description}>
                    <Typography variant='body'>
                        Vei primi un cod pentru {handleAmountOfReward(registerLAUResult.sku_model)} pachete gratuite in cel mai scurt timp, prin SMS.
                    </Typography>
                </Box>

                {productType === PRODUCT_TYPES.iqos && checkEmailResult?.pending_lead_data?.is_loyalty === 0 ? (
                    <Box className={styles.special}>
                        <Typography variant='body'>
                            Alege varianta care ți se potrivește accesând{' '}
                            <Link href='https://iqos.buzz/DescoperaHEETS'>https://iqos.buzz/DescoperaHEETS</Link>.
                        </Typography>
                    </Box>
                ) : null}

                <LoadingButton variant='contained' onClick={gotToShopList} fullWidth>
                    Vezi magazinele
                </LoadingButton>
            </Box>
        </Fade>
    );
};
