import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import App from './containers/App';
import NotFound from './containers/NotFound';
import ShopListContainer from './containers/ShopListContainer';
import UnavailableApp from './containers/UnavailableApp';
import { UnderMaintenanceTemporary } from './components/UnderMaintenanceTemporary';
import { SnackbarUtilsConfigurator } from './helpers/snackbar';

import { store } from './redux/store';
import { theme } from './helpers/theme';
import axiosInstance from './helpers/axios';
import { setInterceptor } from './helpers/axios';
import { isInMaintenance } from './helpers/general';
import './assets/scss/index.scss';

setInterceptor(store, axiosInstance);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                    <SnackbarUtilsConfigurator />
                    <Router basename='/reg'>
                        {isInMaintenance() ? (
                            <Switch>
                                <Route exact path='*' component={UnderMaintenanceTemporary} />
                            </Switch>
                        ) : (
                            <Switch>
                                {/* This route was used to restrict the access to the app. Just uncomment in case of emergency. */}
                                {/* <Route exact path='/' component={UnavailableApp} /> */}
                                {/* <Route exact path='/' component={App} /> */}
                                <Route path='/locatiirevendicare' component={ShopListContainer} />
                                <Route path='/:unique_code' component={App} />
                                <Route path='*' component={NotFound} />
                            </Switch>
                        )}
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
