import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import NotFound from '../../containers/NotFound';
import { ShopList } from '../../components/ShopList';
import { CheckEmail } from '../../components/CheckEmail';
import { WelcomeLAU } from '../../components/WelcomeLAU';
import { RegisterLAS } from '../../components/RegisterLAS';
import { LimitReached } from '../../components/LimitReached';
import { CongratsShop } from '../../components/CongratsShop';
import { CheckPassword } from '../../components/CheckPassword';
import { ActivateAccount } from '../../components/ActivateAccount';
import { RecoverPassword } from '../../components/RecoverPassword';
// import { AccountActivated } from '../../components/AccountActivated';
import { CongratsDiscount } from '../../components/CongratsDiscount';
import { ProductSelection } from '../../components/ProductSelection';
import { WelcomeLAUInactive } from '../../components/WelcomeLAUInactive';
import { WarningLimitReached } from '../../components/WarningLimitReached';
import { RecoverPasswordSuccess } from '../../components/RecoverPasswordSuccess';
import { WelcomeRegistration } from '../../components/WelcomeRegistration';

import { APP_CONFIG } from '../../helpers/config';

import styles from './Steps.module.scss';
import { UnderMaintenance } from '../../components/UnderMaintenance';
import { CheckVideo } from '../../components/CheckVideo';

const renderStep = (stepId) => {
    // return <UnderMaintenance />;
    const {
        WELCOME_REGISTRATION,
        CHECK_EMAIL,
        CHECK_VIDEO,
        CHECK_PASSWORD,
        RECOVER_PASSWORD,
        WELCOME_LAU,
        WELCOME_LAU_INACTIVE,
        REGISTER_LAS,
        DEVICE_ACTIVATED,
        CONGRATS_DISCOUNT,
        RECOVER_PASSWORD_SUCCESS,
        CONGRATS_SHOP,
        ACTIVATE_ACCOUNT,
        // ACCOUNT_ACTIVATED,
        NOT_FOUND,
        SHOP_LIST,
        LIMIT_REACHED,
        PRODUCT_SELECTION,
        WARNING_LIMIT_REACHED,
        UNDER_MAINTENANCE,
    } = APP_CONFIG.steps;
    switch (stepId) {
        case WELCOME_REGISTRATION: {
            return <WelcomeRegistration />;
        }
        case PRODUCT_SELECTION: {
            return <ProductSelection />;
        }
        case CHECK_VIDEO: {
            return <CheckVideo />;
        }
        case CHECK_EMAIL: {
            return <CheckEmail />;
        }
        case CHECK_PASSWORD: {
            return <CheckPassword />;
        }
        case REGISTER_LAS: {
            return <RegisterLAS />;
        }
        case RECOVER_PASSWORD: {
            return <RecoverPassword />;
        }
        case RECOVER_PASSWORD_SUCCESS: {
            return <RecoverPasswordSuccess />;
        }
        case WELCOME_LAU: {
            return <WelcomeLAU />;
        }
        case WELCOME_LAU_INACTIVE: {
            return <WelcomeLAUInactive />;
        }
        case CONGRATS_DISCOUNT: {
            return <CongratsDiscount />;
        }
        case CONGRATS_SHOP: {
            return <CongratsShop />;
        }
        case NOT_FOUND: {
            return <NotFound />;
        }
        case SHOP_LIST: {
            return <ShopList />;
        }
        case LIMIT_REACHED: {
            return <LimitReached />;
        }
        case WARNING_LIMIT_REACHED: {
            return <WarningLimitReached />;
        }
        case ACTIVATE_ACCOUNT: {
            return <ActivateAccount />;
        }
        case UNDER_MAINTENANCE: {
            return <UnderMaintenance />;
        }
        default: {
            return <></>;
        }
    }
};

const Steps = () => {
    const [step, setStep] = useState(null);

    const { stepId } = useSelector((state) => state.form);

    useEffect(() => {
        setStep(renderStep(stepId));
    }, [stepId]);

    return <Box className={styles.container}>{step}</Box>;
};

export default Steps;
