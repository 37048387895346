import * as yup from 'yup';
import classnames from 'classnames';
import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Fade } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { recoverPassword } from '../../redux/form';

import styles from './RecoverPassword.module.scss';
import { APP_CONFIG } from '../../helpers/config';

const schema = yup
    .object({
        email: yup
            .string()
            .trim()
            .email(APP_CONFIG.validationMessages.invalidEmailFormat)
            .required(APP_CONFIG.validationMessages.required),
    })
    .required();

export const RecoverPassword = () => {
    const dispatch = useDispatch();
    const { checkEmailResult } = useSelector((state) => state.form);

    const [loading, setLoading] = useState(false);

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { dirtyFields } = useFormState({
        control,
    });

    useEffect(() => {
        if (checkEmailResult.email) {
            setValue('email', checkEmailResult.email, { shouldDirty: true });
        }
    }, [checkEmailResult, setValue]);

    const onSubmit = (data) => {
        setLoading(true);

        dispatch(recoverPassword(data)).then((response) => {
            if (response?.payload.status === 422) {
                setLoading(false);
                setError('email', {
                    type: 'api_error',
                    message: response.payload.data?.errors?.email?.[0],
                });
                return;
            }
        });
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copy}>
                    <Typography variant='h1' className={styles.title}>
                        Ai uitat parola?
                    </Typography>
                    <Typography variant='body'>
                        Introdu adresa de e-mail pentru a primi link-ul de restare parolă.
                    </Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className={styles.formItem}>
                        <Controller
                            name='email'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    id='outlined-error'
                                    label='Email'
                                    fullWidth
                                />
                            )}
                            defaultValue={''}
                        />
                    </Box>

                    <Box className={styles.nextButton}>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            loading={loading}
                            disabled={Object.keys(dirtyFields).length !== 1}
                        >
                            Trimite
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
        </Fade>
    );
};
