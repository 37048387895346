import { Box } from '@mui/system';
import classnames from 'classnames';
import { Typography, Fade } from '@mui/material';
import styles from './UnderMaintenanceTemporary.module.scss';

export const UnderMaintenanceTemporary = () => {
    return (
        <Fade in>
            <Box className={classnames(styles.parent)}>
                <Box className={classnames('container', styles.customContainerMobile)}>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant='h6'>Mentenanță</Typography>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant='body'>
                            Momentan se desfășoară lucrări de mentenanță pentru îmbunătățirea experienței dumneavoastră. Ne cerem scuze și vă rugăm sa reaccesați link-ul din SMS începând cu ora <b>11:00</b>.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Fade>
    );
};
