import classnames from 'classnames';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Fade, Button, Link } from '@mui/material';

import { resetState } from '../../redux/form';

import styles from './CongratsDiscount.module.scss';
import { PRODUCT_TYPES } from '../../helpers/config';
import { handleAmountOfReward } from '../../helpers/general';

export const CongratsDiscount = () => {
    const dispatch = useDispatch();

    const { checkEmailResult, productType, registerLAUResult } = useSelector((state) => state.form);

    const restart = () => {
        dispatch(resetState());
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.title}>
                    <Typography variant='h1'>Felicitări!</Typography>
                </Box>

                <Box className={styles.description}>
                    {checkEmailResult?.pending_lead_data?.is_loyalty === 0 ? (
                        <Typography variant='body'>
                            A fost activat discount-ul pentru achiziție online din contul tău pe{' '}
                            <Link href='https://www.iqos.ro'>www.iqos.ro</Link>, cu valabilitate până la data de{' '}
                            {dayjs().add(1, 'month').format('DD/MM/YYYY')}, ora 23:59. Discount-ul pentru cele {handleAmountOfReward(registerLAUResult.sku_model)} pachete
                            se va aplica automat în coșul de cumpărături. Costul de livrare este gratuit.
                        </Typography>
                    ) : (
                        <Typography variant='body'>
                            A fost activat discount-ul pentru achiziție online din contul tău pe{' '}
                            <Link href='https://www.iqos.ro'>www.iqos.ro</Link>. Discount-ul se va aplica automat în
                            coșul de cumpărături.
                        </Typography>
                    )}
                </Box>

                {checkEmailResult?.pending_lead_data?.is_loyalty === 0 && productType === PRODUCT_TYPES.iqos ? (
                    <Box className={styles.special}>
                        <Typography variant='body'>
                            Alege varianta care ți se potrivește accesând{' '}
                            <Link href='https://iqos.buzz/DescoperaHEETS'>https://iqos.buzz/DescoperaHEETS</Link>.
                        </Typography>
                    </Box>
                ) : null}

                <Button variant='contained' onClick={restart} fullWidth>
                    Spre ecranul de început
                </Button>
            </Box>
        </Fade>
    );
};
