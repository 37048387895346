import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { Box, CircularProgress } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Steps from '../Steps';
import GoBack from '../../components/GoBack';

import { setUniqueCode, uniqueCodeCheck, setQrCode } from '../../redux/form';

import { SECRET_ROUTE, SECRET_ROUTE_DISABLED } from '../../helpers/config'

import './App.module.scss';

function App() {
    const { unique_code } = useParams();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const [loadApp, setLoadApp] = useState(false);

    useEffect(() => {
        if (unique_code) {
            dispatch(setUniqueCode(unique_code));

            if(!SECRET_ROUTE_DISABLED && unique_code === SECRET_ROUTE) {
              setLoadApp(true);
            } else {
              dispatch(uniqueCodeCheck()).then((response) => {
                  setLoadApp(true);
              });
            }

        } else {
            setLoadApp(true);
        }
    }, [unique_code, dispatch]);

    useEffect(() => {
        const parsed = queryString.parse(search);

        if (parsed?.qr_code === 'true') {
            dispatch(setQrCode());
        }
    }, [search, dispatch]);

    if (!loadApp) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GoBack />
            <Steps />
        </LocalizationProvider>
    );
}

export default App;
