import * as yup from 'yup';
import classnames from 'classnames';
import {Box} from '@mui/system';
import {useDispatch, useSelector} from 'react-redux';
import {DatePicker, LoadingButton} from '@mui/lab';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormHelperDevice} from '../FormHelperDevice';
import {useForm, Controller, useFormState} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {
    Fade,
    Typography,
    TextField,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Link,
    Checkbox,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';

import {
    checkPosCode,
    registerLAS,
    setPosInfo,
    setStoreIqosType,
    validatePhoneNumber
} from '../../redux/form';
import {formErrorHandler, handleAmountOfReward, isFallbackValidationOn} from '../../helpers/general';
import {APP_CONFIG, DEFAULT_IQOS_TYPE, IQOS_TYPES, PRODUCT_TYPES} from '../../helpers/config';
import SnackbarService from '../../helpers/snackbar';

import styles from './RegisterLAS.module.scss';

const schemaFields = {
    phone: yup
        .string()
        .trim()
        .transform((v) => v.replace(/\s/g, ''))
        .matches(APP_CONFIG.regexPhoneNumber, APP_CONFIG.validationMessages.invalidPhoneFormat)
        .required(APP_CONFIG.validationMessages.required),
    firstname: yup
        .string()
        .matches(APP_CONFIG.regexNames, APP_CONFIG.validationMessages.invalidNameFormat)
        .min(APP_CONFIG.minCharacters, APP_CONFIG.validationMessages.min())
        .max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max())
        .required(APP_CONFIG.validationMessages.required),
    lastname: yup
        .string()
        .matches(APP_CONFIG.regexNames, APP_CONFIG.validationMessages.invalidNameFormat)
        .min(APP_CONFIG.minCharacters, APP_CONFIG.validationMessages.min())
        .max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max())
        .required(APP_CONFIG.validationMessages.required),
    birthday: yup.date(APP_CONFIG.validationMessages.required).required(APP_CONFIG.validationMessages.required),
    accessory_code: yup
        .string()
        .matches(APP_CONFIG.regexAccessoryCode, APP_CONFIG.validationMessages.invalidNameFormat)
        .min(APP_CONFIG.minAccessoryCode, APP_CONFIG.validationMessages.min(APP_CONFIG.minAccessoryCode))
        .max(APP_CONFIG.maxAccessoryCode, APP_CONFIG.validationMessages.max(APP_CONFIG.maxAccessoryCode))
        .required(APP_CONFIG.validationMessages.required),
    pos_code: yup
        .string()
        .min(APP_CONFIG.minPosCode, APP_CONFIG.validationMessages.min(APP_CONFIG.minPosCode))
        .max(APP_CONFIG.maxPosCode, APP_CONFIG.validationMessages.max(APP_CONFIG.maxPosCode))
        .required(APP_CONFIG.validationMessages.required),
    civility: yup.number().required(APP_CONFIG.validationMessages.required),
    marketing_opt_in: yup.bool().required(APP_CONFIG.validationMessages.required),
    terms_and_conditions: yup.bool().oneOf([true], APP_CONFIG.validationMessages.required),
};

if (isFallbackValidationOn()) {
    schemaFields.device_code = yup
        .string()
        .matches(APP_CONFIG.regexDeviceCode, APP_CONFIG.validationMessages.invalidNameFormat)
        .min(APP_CONFIG.minDeviceCode, APP_CONFIG.validationMessages.min(APP_CONFIG.minDeviceCode))
        .max(APP_CONFIG.maxDeviceCode, APP_CONFIG.validationMessages.max(APP_CONFIG.maxDeviceCode))
        .required(APP_CONFIG.validationMessages.required);
}

const schema = yup.object(schemaFields).required();

const calculateNegativeMargin = (isIqos, isThirdpartyRadioDisplayed, hasPosError) => {
    if (isIqos && isThirdpartyRadioDisplayed) {
        return {marginTop: `-60px`};
    } else if (isIqos) {
        return {marginTop: `-56px`};
    } else if (isThirdpartyRadioDisplayed) {
        return {marginTop: `0`}; 
        // return {marginTop: `-67px`}; // previously used third party radio such as glovo no longer used
    } else {
        return {marginTop: hasPosError ? '-40px' : 'initial'};
    }
};

export const RegisterLAS = () => {
    const dispatch = useDispatch();
    const {checkEmailResult, productType, posInfo, phoneNumberValidation} = useSelector((state) => state.form);

    const isIqos = productType === PRODUCT_TYPES.iqos;
    const isThirdpartyRadioDisplayed = posInfo?.parent && posInfo?.parent !== null;

    const [loading, setLoading] = useState(false);
    const [disablePosCode, setDisablePosCode] = useState(false);
    const [disablePhone, setDisablePhone] = useState(false);
    const [iqosType, setIqosType] = useState(DEFAULT_IQOS_TYPE);
    const [disableLastName, setDisableLastName] = useState(false);
    const [disableFirstName, setDisableFirstName] = useState(false);
    const [showPhoneNumberDisclaimer, setShowPhoneNumberDisclaimer] = useState(false);
    const [showPhoneNumberAlreadyValidatedDisclaimer, setShowPhoneNumberAlreadyValidatedDisclaimer] = useState(false);

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        getValues,
        formState: {errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const {dirtyFields} = useFormState({
        control,
    });

    // TODO -> check how the schema was build for the form, 
    // the isValid field from formState is returning false even if all the fields are filled and no errors are returned
    const disableSubmit = Object.values(getValues()).some(fieldValue => !fieldValue) && dirtyFields;

    const negativeMargin = calculateNegativeMargin(isIqos, isThirdpartyRadioDisplayed, !!errors.pos_code);

    useEffect(() => {
        if (checkEmailResult?.pending_lead_data?.pos_code) {
            handlePosCode(checkEmailResult.pending_lead_data.pos_code);
            setValue('pos_code', checkEmailResult.pending_lead_data.pos_code, {shouldDirty: true});
            setDisablePosCode(true);
        }
        if (checkEmailResult?.pending_lead_data?.phone_number) {
            dispatch(validatePhoneNumber({
                phone_number: checkEmailResult.pending_lead_data.phone_number
            }));
            setValue('phone', checkEmailResult.pending_lead_data.phone_number, {shouldDirty: true});
            setDisablePhone(true);
        }
        if (checkEmailResult?.pending_lead_data?.first_name) {
            setValue('firstname', checkEmailResult.pending_lead_data.first_name, {shouldDirty: true});
            setDisableFirstName(true);
        }
        if (checkEmailResult?.pending_lead_data?.last_name) {
            setValue('lastname', checkEmailResult.pending_lead_data.last_name, {shouldDirty: true});
            setDisableLastName(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkEmailResult, setValue]);

    useEffect(() => {
        if (phoneNumberValidation && phoneNumberValidation.phoneNumberIsAssociatedOnOtherAccounts && phoneNumberValidation.phone_validated === false && checkEmailResult.pending_lead_data.user_type === 'LAS') {
            setShowPhoneNumberDisclaimer(true);
        }
        if (phoneNumberValidation && phoneNumberValidation.phone_validated && checkEmailResult.pending_lead_data.user_type === 'LAS') {
            setShowPhoneNumberAlreadyValidatedDisclaimer(true);
        }
    }, [phoneNumberValidation])

    const onSubmit = (data) => {
        setLoading(true);

        const body = {
            ...data,
            email: checkEmailResult.email,
            lead_id: checkEmailResult?.pending_lead_data?.id,
        };

        dispatch(registerLAS(body)).then((response) => {
            if (response?.payload.status === 422) {
                const errors = response.payload?.data?.errors;
                if (!errors) {
                    return;
                }

                clearErrors();

                formErrorHandler(errors, setError);

                setLoading(false);
            }
        });
    };

    const handleIqosTypeChange = (event) => {
        setIqosType(event.target.value);
        dispatch(setStoreIqosType(event.target.value));
    };

    const handlePosCode = (value) => {
        if (value?.length === 5) {
            dispatch(checkPosCode({id: value})).then((response) => {
                if (response?.payload.status === 422) {
                    const errors = response.payload?.data?.errors;

                    if (!errors) {
                        return;
                    }

                    formErrorHandler(errors, setError);
                }

                if (response?.meta?.requestStatus === APP_CONFIG.requestStatusFulfilled) {
                    clearErrors('pos_code');
                }
            });
        } else {
            if (posInfo) {
                dispatch(setPosInfo(null));
            }
        }
    };

    return (
        <Fade in>
            <Box className={styles.container}>
                <Box className={styles.title}>
                    <Typography variant='h1'>Continuă înregistrarea</Typography>
                </Box>

                <Box className={styles.details}>
                    <Typography variant='body' component={'div'}>
                        Nu ai un cont creat cu acest e-mail: {checkEmailResult.email}. Creează-l acum!
                        <Typography className={styles.mobileDescription}>
                            <b>Completează datele și primești pachete de rezerve. Pentru mai multe detalii consultă regulamentul la{' '}
                            <Link
                                href='www.iqos.ro/regulament-promo-kit-parteneri'
                                color={'#000'}
                                variant={'bold'}
                            >
                                www.iqos.ro/regulament-promo-kit-parteneri
                            </Link>.</b>
                        </Typography>
                    </Typography>
                </Box>
                <Box className={styles.note}>
                    <Typography variant='small'>NOTĂ: Toate câmpurile sunt obligatorii.</Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className={styles.rowContainer}>
                        <Box>
                            <Typography variant='subtitle' className={styles.formSubtitle}>
                                Date personale
                            </Typography>
                            <Box>
                                <Controller
                                    name='firstname'
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            disabled={disableFirstName}
                                            className={styles.formItem}
                                            required
                                            error={!!errors.firstname}
                                            helperText={errors?.firstname?.message}
                                            id='outlined-error'
                                            label='Prenume'
                                        />
                                    )}
                                    defaultValue={''}
                                />
                                <Controller
                                    name='lastname'
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            disabled={disableLastName}
                                            className={styles.formItem}
                                            required
                                            error={!!errors.lastname}
                                            helperText={errors?.lastname?.message}
                                            id='outlined-error'
                                            label='Nume'
                                        />
                                    )}
                                    defaultValue={''}
                                />
                            </Box>
                            <Box>
                                <Controller
                                    name='birthday'
                                    control={control}
                                    render={({field: {ref, ...rest}}) => (
                                        <DatePicker
                                            {...rest}
                                            label='Dată naștere'
                                            inputFormat='DD/MM/YYYY'
                                            disableFuture
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    error={!!errors.birthday}
                                                    className={classnames(styles.formItem, styles.datepicker)}
                                                    helperText={errors?.birthday?.message}
                                                    id='outlined-error'
                                                />
                                            )}
                                        />
                                    )}
                                    defaultValue={null}
                                />
                                <Controller
                                    name='phone'
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            className={styles.formItem}
                                            required
                                            disabled={disablePhone}
                                            error={!!errors.phone}
                                            helperText={errors?.phone?.message}
                                            id='outlined-error'
                                            label='Număr de telefon'
                                        />
                                    )}
                                    defaultValue={''}
                                />
                            </Box>
                            <Box>
                                <Controller
                                    name='civility'
                                    control={control}
                                    render={({field}) => (
                                        <Box className={styles.formItemRadio}>
                                            <FormLabel component='legend' className={styles.label}>
                                                Gen
                                            </FormLabel>
                                            <RadioGroup row aria-label='gen' name='civility' {...field}>
                                                <FormControlLabel
                                                    value='1'
                                                    control={<Radio/>}
                                                    label='Masculin'
                                                    className={styles.radioGroup}
                                                />
                                                <FormControlLabel
                                                    value='2'
                                                    control={<Radio/>}
                                                    label='Feminin'
                                                    className={styles.radioGroup}
                                                />
                                            </RadioGroup>
                                        </Box>
                                    )}
                                    defaultValue={null}
                                />
                            </Box>
                        </Box>

                        <Box className={styles.separatorVertical}></Box>

                        <Box>
                            <Typography variant='subtitle' className={styles.formSubtitle}>
                                Date despre dispozitiv
                            </Typography>
                            <Box>
                                {isIqos ? (
                                    <Box className={styles.formItemDevice}>
                                        <FormControl fullWidth>
                                            <InputLabel id='iqos-type'>Tip dispozitiv</InputLabel>
                                            <Select
                                                labelId='iqos-type'
                                                id='iqos-tpye'
                                                value={iqosType}
                                                label='Tip dispozitiv'
                                                onChange={handleIqosTypeChange}
                                            >
                                                {IQOS_TYPES.map(({label, value}) => (
                                                    <MenuItem
                                                        key={value.replace(/\s+/gi, '-').toLocaleLowerCase()}
                                                        value={value}
                                                    >
                                                        {label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                ) : null}

                                <Controller
                                    name='accessory_code'
                                    control={control}
                                    render={({field: {onChange, ...rest}}) => (
                                        <Box className={styles.formItemDevice}>
                                            <TextField
                                                {...rest}
                                                required
                                                error={!!errors.accessory_code}
                                                helperText={errors?.accessory_code?.message}
                                                label='Cod componentă'
                                                onChange={(ev) => {
                                                    const value = ev?.target?.value?.replace(/\s+/gi, '');
                                                    onChange(value);
                                                }}
                                                fullWidth
                                                aria-describedby='cod-componentă'
                                            />
                                            <FormHelperDevice
                                                type={APP_CONFIG.accessoryId}
                                                id='cod-componentă'
                                                copy={'Unde găsesc codul componentei?'}
                                            />
                                        </Box>
                                    )}
                                    defaultValue={''}
                                />

                                <Controller
                                    name='pos_code'
                                    control={control}
                                    render={({field}) => (
                                        <Box className={styles.formItemDevice}>
                                            <TextField
                                                {...field}
                                                required
                                                error={!!errors.pos_code}
                                                helperText={errors?.pos_code?.message}
                                                label='Cod magazin'
                                                fullWidth
                                                aria-describedby='cod-magazin'
                                                disabled={disablePosCode}
                                                onChange={(e) => {
                                                    handlePosCode(e?.target?.value);
                                                    field?.onChange?.(e);

                                                    return e;
                                                }}
                                            />
                                            <FormHelperDevice
                                                type={APP_CONFIG.shopCodeId}
                                                id='cod-magazin'
                                                copy={'Unde găsesc codul magazinului?'}
                                            />
                                        </Box>
                                    )}
                                    defaultValue={''}
                                />

                                {/* Hide Glovo as per - PTQR-541 */}
                                {/* {posInfo?.parent && posInfo?.parent !== null ? (
                                    <Box className={classnames(styles.formItem, styles.glovo)}>
                                        <Controller
                                            name='third_party_delivered'
                                            control={control}
                                            render={({field}) => (
                                                <Box className={styles.formItemRadio}>
                                                    <FormLabel component='legend' className={styles.label}>
                                                        <Typography>Achizițional prin Glovo?</Typography>
                                                    </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-label='third_party_delivered'
                                                        name='third_party_delivered'
                                                        className={styles.glovoRadioGroup}
                                                        {...field}
                                                    >
                                                        <FormControlLabel
                                                            value={true}
                                                            control={<Radio/>}
                                                            label='Da'
                                                            className={styles.radioGroup}
                                                        />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio/>}
                                                            label='Nu'
                                                            className={styles.radioGroup}
                                                        />
                                                    </RadioGroup>
                                                </Box>
                                            )}
                                            defaultValue={null}
                                        />
                                    </Box>
                                ) : null} */}

                                {isFallbackValidationOn() ? (
                                    <Controller
                                        name='device_code'
                                        control={control}
                                        render={({field}) => (
                                            <Box className={styles.formItemDevice}>
                                                <TextField
                                                    {...field}
                                                    required
                                                    error={!!errors.device_code}
                                                    helperText={errors?.device_code?.message}
                                                    label='Cod dispozitiv'
                                                    fullWidth
                                                    aria-describedby='cod-dispozitiv'
                                                />
                                                <FormHelperDevice
                                                    type={APP_CONFIG.deviceCodeId}
                                                    id='cod-dispozitiv'
                                                    copy={'Unde găsesc codul dispozitivului?'}
                                                />
                                            </Box>
                                        )}
                                        defaultValue={''}
                                    />
                                ) : null}
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classnames(styles.marketing)} style={negativeMargin}>
                        <Controller
                            name='marketing_opt_in'
                            control={control}
                            render={({field}) => (
                                <Box className={styles.formItemRadio}>
                                    <FormLabel component='legend' className={styles.label}>
                                        Sunt de acord să primesc prin e-mail sau SMS informații comerciale despre IQOS,
                                        HEETS, TEREA, LEVIA, lil Solid, FIIT sau alte produse care nu implică arderea tutunului şi
                                        servicii conexe acestor produse, oferite de Philip Morris Trading S.R.L sau de
                                        partenerii săi.
                                        <Typography component='div'>
                                            <Typography
                                                component='p'
                                                variant='bold'
                                                className={styles.marketingBenefits}
                                            >
                                                Doresc să am acces la beneficii:
                                            </Typography>
                                            <ul>
                                                <li>ofertele exclusive</li>
                                                <li>reduceri</li>
                                                <li>vouchere</li>
                                            </ul>
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup row aria-label='marketing_opt_in' name='marketing_opt_in' {...field}>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio/>}
                                            label='Da'
                                            className={styles.radioGroup}
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio/>}
                                            label='Nu'
                                            className={styles.radioGroup}
                                        />
                                    </RadioGroup>
                                </Box>
                            )}
                            defaultValue={null}
                        />
                    </Box>

                    <Box className={styles.separatorHorizontal}></Box>

                    {
                        showPhoneNumberDisclaimer &&
                        <Box>
                            <FormLabel component='legend' className={styles.phoneNumberDisclaimer}>
                                Acest număr de telefon va fi asociat acestui cont, în cazul în care nu este validat pe
                                un cont deja existent.
                            </FormLabel>
                        </Box>
                    }
                    <Box>
                        <Controller
                            name='terms_and_conditions'
                            control={control}
                            render={({field}) => (
                                <FormControl error={!!errors.terms_and_conditions}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        labelPlacement={'end'}
                                        className={styles.terms_and_conditions}
                                        label={
                                            <Typography>
                                                *Am citit și înțeles{' '}
                                                <Link
                                                    href='https://www.pmiprivacy.com/ro/consumer'
                                                    color={'#000'}
                                                    variant={'bold'}
                                                >
                                                    Notificarea de protecție a datelor cu caracter personal
                                                </Link>
                                            </Typography>
                                        }
                                    />
                                    <FormHelperText>{errors?.terms_and_conditions?.message}</FormHelperText>
                                </FormControl>
                            )}
                            defaultValue={false}
                        />
                    </Box>
                    {
                        showPhoneNumberAlreadyValidatedDisclaimer &&
                        <Box>
                            <FormLabel component='legend'
                                       className={classnames(styles.phoneNumberDisclaimer, styles.error)}>
                                Numărul de telefon furnizat este validat pe un cont deja existent. Vă rugăm să
                                introduceți un alt număr de telefon sau să ne contactați la <a href={"tel:0800030333"}>0800030333</a>.</FormLabel>
                        </Box>
                    }
                    <Box className={styles.nextButton}>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            loading={loading}
                            disabled={disableSubmit || showPhoneNumberAlreadyValidatedDisclaimer}
                        >
                            Pasul următor
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
        </Fade>
    );
};
