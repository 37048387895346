import { useState } from 'react';
import { Box } from '@mui/system';
import classnames from 'classnames';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Fade, Typography, Dialog, DialogTitle, DialogContent, IconButton, Button, Link } from '@mui/material';

import SnackbarService from '../../helpers/snackbar';
import { ReactComponent as RetrySvg } from '../../assets/images/retry.svg';

import { refreshEmail, resendActivateDeviceEmail } from '../../redux/form';

import styles from './WelcomeLAUInactive.module.scss';
import { APP_CONFIG } from '../../helpers/config';

export const WelcomeLAUInactive = () => {
    const dispatch = useDispatch();

    const { checkEmailResult } = useSelector((state) => state.form);

    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const resendEmail = () => {
        dispatch(resendActivateDeviceEmail({ dcs_id: checkEmailResult.dcs_id })).then((response) => {
            if (response?.meta?.requestStatus === APP_CONFIG.requestStatusFulfilled) {
                setOpened(true);
                return;
            }
            SnackbarService.toast('S-a întâmplat ceva, încearcă din nou.');
        });
    };

    const next = () => {
        setLoading(true);
        dispatch(refreshEmail({ email: checkEmailResult.email })).then((response) => {
            setLoading(false);
        });
    };

    return (
        <>
            <Dialog open={opened} className={styles.modalBody}>
                <DialogTitle>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpened(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#000',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant='body' sx={{ textAlign: 'center' }}>
                            Am trimis un e-mail de activare a contului pe adresa {checkEmailResult.email}. Accesează
                            link-ul pentru activarea contului.
                        </Typography>
                        <Typography variant='bold' component='p' sx={{ textAlign: 'center', color: '#d4712d' }}>
                            După activarea contului, nu uita să revii în această pagină pentru revendicarea celor 3
                            pachete.
                        </Typography>
                        <Box className={styles.modalButtonContainer}>
                            <Button variant='contained' onClick={() => setOpened(false)} fullWidth>
                                Închide notificarea
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Fade in>
                <Box className={classnames('container', styles.customContainerMobile)}>
                    <Box className={styles.copy}>
                        <Typography variant='body'>
                            Contul tău este inactiv pe <Link href='https://www.iqos.ro'>www.iqos.ro</Link>. Te rugăm să
                            activezi contul pentru a continua înregistrarea.
                        </Typography>
                    </Box>

                    <Box className={styles.nextButton}>
                        <LoadingButton variant='contained' type='submit' fullWidth onClick={next} loading={loading}>
                            Pasul următor
                        </LoadingButton>
                    </Box>

                    <Box className={styles.resendEmail} onClick={resendEmail}>
                        <span>Activează contul</span>
                        <RetrySvg />
                    </Box>
                </Box>
            </Fade>
        </>
    );
};
