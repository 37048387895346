import { Box } from '@mui/system';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, Fade, Button } from '@mui/material';

import { resetState } from '../../redux/form';

import styles from './LimitReached.module.scss';

export const LimitReached = () => {
    const dispatch = useDispatch();

    const restart = () => {
        dispatch(resetState());
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copy}>
                    <Typography variant='body'>
                        Codul dispozitivului a fost inregistrat cu succes. Ai beneficiat deja de numarul maxim de oferte
                        disponibile. Nu vei mai avea posibilitatea de a alege un reward.
                    </Typography>
                </Box>

                <Button variant='contained' onClick={restart} fullWidth>
                    Spre ecranul de început
                </Button>
            </Box>
        </Fade>
    );
};
