import { combineReducers, configureStore } from '@reduxjs/toolkit';

import form from './form';
import shops from './shops';

const reducers = combineReducers({
    form: form,
    shops: shops,
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
    enhancers: [],
});
