import { isFallbackValidationOn } from './general';

export const APP_CONFIG = {
    steps: {
        WELCOME_REGISTRATION: 'WELCOME_REGISTRATION',
        PRODUCT_SELECTION: 'PRODUCT_SELECTION',
        CHECK_VIDEO: 'CHECK_VIDEO',
        CHECK_EMAIL: 'CHECK_EMAIL',
        CHECK_PASSWORD: 'CHECK_PASSWORD',
        RECOVER_PASSWORD: 'RECOVER_PASSWORD',
        RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
        WELCOME_LAU: 'WELCOME_LAU',
        WELCOME_LAU_INACTIVE: 'WELCOME_LAU_INACTIVE',
        REGISTER_LAS: 'REGISTER_LAS',
        DEVICE_ACTIVATED: 'DEVICE_ACTIVATED',
        CONGRATS_DISCOUNT: 'CONGRATS_DISCOUNT',
        CONGRATS_SHOP: 'CONGRATS_SHOP',
        // ACCOUNT_ACTIVATED: 'ACCOUNT_ACTIVATED',
        NOT_FOUND: 'NOT_FOUND',
        SHOP_LIST: 'SHOP_LIST',
        LIMIT_REACHED: 'LIMIT_REACHED',
        WARNING_LIMIT_REACHED: 'WARNING_LIMIT_REACHED',
        ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
        UNDER_MAINTENANCE: 'UNDER_MAINTENANCE',
    },
    // eslint-disable-next-line
    regexPhoneNumber: /^(\+4|)?(07[0-9]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/gim,
    regexNames: /^([a-zA-ZÀ-ÖØ-öø-įǸ-ț\s-]+)$/,
    regexDeviceCode: /^(?:(?!o)[a-z0-9])+$/i,
    regexAccessoryCode: /^(?:(?!o)[a-z0-9])+$/i,
    regexBlacklistEmails: /^(?!(.*@yopmail.com)$).*$/i,
    validationMessages: {
        required: 'Acest câmp este obligatoriu.',
        invalidPhoneFormat: 'Format invalid',
        invalidNameFormat: 'Format nume invalid',
        invalidEmailFormat: 'Adresa de e-mail nu respectă formatul standard.',
        emailDontMatch: 'Adresele de e-mail nu coincid.',
        min: (no = 3) => `Acest câmp trebuie să aibă minim ${no} caractere.`,
        max: (no = 128) => `Acest câmp trebuie să aibă maxim ${no} caractere.`,
    },
    minCharacters: 3,
    maxCharacters: 128,
    minAccessoryCode: isFallbackValidationOn() ? 4 : 14,
    maxAccessoryCode: isFallbackValidationOn() ? 4 : 14,
    minPosCode: 5,
    maxPosCode: 5,
    minDeviceCode: 12,
    maxDeviceCode: 12,
    deviceCodeId: 'deviceCodeId',
    shopCodeId: 'shopCodeId',
    accessoryId: 'accessoryId',
    requestStatusFulfilled: 'fulfilled',
};

export const PRODUCT_TYPES = {
    iqos: 'iqos',
    lil: 'lil',
};

export const IQOS_TYPES = [
    {
        value: 'DUO',
        label: 'IQOS DUO',
    },
    {
        value: 'IQOS Originals ONE',
        label: 'IQOS Originals ONE',
    },
    {
        value: 'IQOS Originals DUO',
        label: 'IQOS Originals DUO',
    },
    {
        value: 'IQOS Iluma',
        label: 'IQOS Iluma',
    },
    {
        value: 'IQOS Iluma Prime',
        label: 'IQOS Iluma Prime',
    },
    {
        value: 'IQOS Iluma One',
        label: 'IQOS Iluma One',
    },
];

export const DEFAULT_IQOS_TYPE = IQOS_TYPES[0].value;
export const STARTING_STEP = APP_CONFIG.steps.WELCOME_REGISTRATION;

export const GO_BACK_FORBIDDEN_STEPS = [
    STARTING_STEP,
    APP_CONFIG.steps.DEVICE_ACTIVATED,
    APP_CONFIG.steps.LIMIT_REACHED,
    APP_CONFIG.steps.CONGRATS_DISCOUNT,
    APP_CONFIG.steps.RECOVER_PASSWORD_SUCCESS,
    APP_CONFIG.steps.CONGRATS_SHOP,
    // APP_CONFIG.steps.ACCOUNT_ACTIVATED,
    APP_CONFIG.steps.NOT_FOUND,
];

export const UNIQUE_CODE_REQUIRED_ENDPOINTS = [
    '/users/search',
    '/users/register-las',
    '/users/register-lau',
    '/users/login',
    '/users/check-unique-code',
    '/users/redeem-online',
];

export const QR_CODE_REQUIRED_ENDPOINTS = ['/users/search'];

export const PRODUCT_TYPE_REQUIRED_ENDPOINTS = ['/users/search'];
export const SECRET_ROUTE_DISABLED = true;
export const SECRET_ROUTE = '7d9ae376-3fdc-11ed-b878-0242ac120002';
