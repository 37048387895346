// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnderMaintenance_nextButton__DwBBh {\n  margin-top: 16px !important;\n}\n\n.UnderMaintenance_sms__3jgUL {\n  color: #00d1d2;\n  font-size: 16px;\n  line-height: 21px;\n  letter-spacing: 0;\n}\n.UnderMaintenance_sms__3jgUL:hover {\n  cursor: pointer;\n}\n\n@media only screen and (max-width: 432px) {\n  .UnderMaintenance_customContainerMobile__3j2GY {\n    padding-top: 32px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/UnderMaintenance/UnderMaintenance.module.scss","webpack://src/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA;EACI,2BAAA;AADJ;;AAIA;EACI,cCLY;EDMZ,eAAA;EACA,iBAAA;EACA,iBAAA;AADJ;AAGI;EACI,eAAA;AADR;;AAKA;EACI;IACI,iBAAA;EAFN;AACF","sourcesContent":["@import '../../assets/scss/variables';\n\n.nextButton {\n    margin-top: #{$default-spacing-unit * 2} !important;\n}\n\n.sms {\n    color: $primary-color;\n    font-size: 16px;\n    line-height: 21px;\n    letter-spacing: 0;\n\n    &:hover {\n        cursor: pointer;\n    }\n}\n\n@media only screen and (max-width: $mobile-breakpoint) {\n    .customContainerMobile {\n        padding-top: #{$default-spacing-unit * 4};\n    }\n}\n","$white-color: #fff;\n$black-color: #000;\n$primary-color: #00d1d2;\n$secondary-color: #34303d;\n$primary-background-color: #f6f4f0;\n$custom-orange-color: #d4712d;\n$default-spacing-unit: 8px;\n\n$mobile-breakpoint: 432px;\n$mobile-register-breakpoint: 708px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nextButton": "UnderMaintenance_nextButton__DwBBh",
	"sms": "UnderMaintenance_sms__3jgUL",
	"customContainerMobile": "UnderMaintenance_customContainerMobile__3j2GY"
};
export default ___CSS_LOADER_EXPORT___;
