// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormHelperDevice_formItemHelper__1J1dx {\n  display: flex;\n  align-items: center;\n}\n.FormHelperDevice_formItemHelper__1J1dx small:hover {\n  cursor: pointer;\n}\n.FormHelperDevice_formItemHelper__1J1dx > svg {\n  margin-right: 8px;\n}\n.FormHelperDevice_formItemHelper__1J1dx > svg:hover {\n  cursor: pointer;\n}\n\n.FormHelperDevice_modalBody__3HBjT img {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/FormHelperDevice/FormHelperDevice.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;AADJ;AAIQ;EACI,eAAA;AAFZ;AAMI;EACI,iBAAA;AAJR;AAKQ;EACI,eAAA;AAHZ;;AASI;EACI,WAAA;AANR","sourcesContent":["@import '../../assets/scss/variables';\n\n.formItemHelper {\n    display: flex;\n    align-items: center;\n\n    small {\n        &:hover {\n            cursor: pointer;\n        }\n    }\n\n    > svg {\n        margin-right: #{$default-spacing-unit};\n        &:hover {\n            cursor: pointer;\n        }\n    }\n}\n\n.modalBody {\n    img {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItemHelper": "FormHelperDevice_formItemHelper__1J1dx",
	"modalBody": "FormHelperDevice_modalBody__3HBjT"
};
export default ___CSS_LOADER_EXPORT___;
