import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Fade } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    Autocomplete,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Pagination,
} from '@mui/material';

import { APP_CONFIG } from '../../helpers/config';
import { searchShops, getCities, getCounties } from '../../redux/shops';

import { ReactComponent as SearchSvg } from '../../assets/images/search.svg';
import { ReactComponent as NoDataFoundSvg } from '../../assets/images/no-data-found.svg';

import styles from './ShopList.module.scss';

const schema = yup
    .object({
        query: yup.string().max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max()),
    })
    .required();

export const ShopList = () => {
    const dispatch = useDispatch();

    const { results, pager, loading, counties, cities } = useSelector((state) => state.shops);
    const [query, setQuery] = useState(null);
    const [county, setCounty] = useState(undefined);
    const [city, setCity] = useState(undefined);

    useEffect(() => {
        dispatch(searchShops());
        dispatch(getCounties());
        dispatch(getCities());
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        const newQuery = data?.query || '';
        setQuery(newQuery);

        setCounty(data?.county);
        setCity(data?.city);

        const resetPager = data?.county !== county || data?.city !== city;

        dispatch(
            searchShops({
                query: newQuery,
                city: data?.city,
                county: data?.county,
                per_page: pager.per_page,
                page: resetPager ? 1 : pager.current_page,
            }),
        );
    };

    const handleChangePage = (event, newPage) => {
        const queryParams = {
            query: query,
            city: city,
            county: county,
            per_page: pager.per_page,
            page: newPage,
        };

        dispatch(searchShops(queryParams));
    };

    return (
        <Fade in>
            <Box className={styles.container}>
                <Box className={styles.title}>
                    <Typography variant='h1'>Locații magazine revendicare</Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <Box className={styles.location}>
                        <Box>
                            <Controller
                                name='query'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.query}
                                        helperText={errors?.query?.message}
                                        id='outlined-error'
                                        label='Locație'
                                        placeholder='Caută aici...'
                                        fullWidth
                                    />
                                )}
                                defaultValue={''}
                            />
                        </Box>
                        <Box className={styles.submitButtonMobile}>
                            <LoadingButton
                                type='submit'
                                loading={loading}
                                variant='contained'
                                className={styles.searchButton}
                            >
                                <SearchSvg />
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Box className={styles.filters}>
                        <Box>
                            <Controller
                                name='county'
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Autocomplete
                                        disablePortal
                                        id='counties'
                                        options={counties}
                                        getOptionLabel={(p) => p}
                                        noOptionsText={'Nu sunt opțiuni'}
                                        // sx={{ width: 200 }}
                                        onChange={(event, newValue) => {
                                            onChange(newValue);
                                            return newValue;
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Județ' />}
                                    />
                                )}
                                // defaultValue={null}
                            />
                        </Box>
                        <Box>
                            <Controller
                                name='city'
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Autocomplete
                                        disablePortal
                                        id='cities'
                                        options={cities}
                                        getOptionLabel={(p) => p}
                                        noOptionsText={'Nu sunt opțiuni'}
                                        // sx={{ width: 200 }}
                                        onChange={(event, newValue) => {
                                            onChange(newValue);
                                            return newValue;
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Oraș' />}
                                    />
                                )}
                                // defaultValue={null}
                            />
                        </Box>
                    </Box>

                    <Box className={styles.submitButtonDesktop}>
                        <LoadingButton
                            type='submit'
                            loading={loading}
                            variant='contained'
                            className={styles.searchButton}
                        >
                            <SearchSvg />
                        </LoadingButton>
                    </Box>
                </form>

                {results.length === 0 ? (
                    <NoDataFound />
                ) : (
                    <DataContainer results={results} pager={pager} handleChangePage={handleChangePage} />
                )}
            </Box>
        </Fade>
    );
};

const NoDataFound = () => {
    return (
        <Box className={styles.noDataFoundContainer}>
            <Box>
                <NoDataFoundSvg />
                <Typography variant='body'>Ne pare rău, dar nu sunt rezultate. Încearcă altă căutare.</Typography>
            </Box>
        </Box>
    );
};

const DataContainer = (props) => {
    return (
        <>
            <DataContainerDesktop {...props} />
            <DataContainerMobile {...props} />
        </>
    );
};

const DataContainerDesktop = ({ results, pager, handleChangePage }) => {
    return (
        <Box>
            <TableContainer className={styles.tableContainer}>
                <Table aria-label='lista magazine' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nume magazin</TableCell>
                            <TableCell>Adresă</TableCell>
                            <TableCell>Oraș</TableCell>
                            <TableCell>Județ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((row) => (
                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.address}</TableCell>
                                <TableCell>{row.city}</TableCell>
                                <TableCell>{row.county}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                page={pager.current_page - 1}
                                // component='div'
                                rowsPerPage={parseInt(pager.per_page, 10)}
                                rowsPerPageOptions={[parseInt(pager.per_page, 10)]}
                                count={pager.total}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage + 1)}
                                // onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
};

const DataContainerMobile = ({ results, pager, handleChangePage }) => {
    return (
        <Box className={styles.mobileWrapper}>
            <Box className={styles.mobileContainer}>
                {results.map((item, index) => (
                    <DataRowMobile key={index} item={item} />
                ))}
            </Box>

            <Box className={styles.mobilePagination}>
                <Pagination
                    count={Math.ceil(pager.total / pager.per_page)}
                    page={pager.current_page}
                    onChange={handleChangePage}
                    variant='outlined'
                    shape='rounded'
                    size='small'
                />
            </Box>
        </Box>
    );
};

const DataRowMobile = ({ item }) => {
    return (
        <Box className={styles.mobileItem}>
            <Box>
                <Typography variant='subtitle' className={styles.formSubtitle}>
                    Nume magazin
                </Typography>
                <Typography>{item.name}</Typography>
            </Box>
            <Box>
                <Typography variant='subtitle' className={styles.formSubtitle}>
                    Adresă
                </Typography>
                <Typography>{item.address}</Typography>
            </Box>
            <Box>
                <Typography variant='subtitle' className={styles.formSubtitle}>
                    Oraș
                </Typography>
                <Typography>{item.city}</Typography>
            </Box>
            <Box>
                <Typography variant='subtitle' className={styles.formSubtitle}>
                    Județ
                </Typography>
                <Typography>{item.county}</Typography>
            </Box>
        </Box>
    );
};
