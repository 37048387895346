import * as yup from 'yup';
import { useState } from 'react';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff, KeyboardBackspaceRounded } from '@mui/icons-material';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { TextField, Typography, InputAdornment, IconButton, Link, Fade } from '@mui/material';

import { APP_CONFIG } from '../../helpers/config';
import { login, setStepId } from '../../redux/form';

import styles from './CheckPassword.module.scss';

const schema = yup
    .object({
        password: yup.string().required(APP_CONFIG.validationMessages.required),
    })
    .required();

export const CheckPassword = () => {
    const dispatch = useDispatch();
    const { checkEmailResult } = useSelector((state) => state.form);

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { dirtyFields } = useFormState({
        control,
    });

    const onSubmit = (data) => {
        const loginBody = {
            ...data,
            email: checkEmailResult?.pending_lead_data?.email,
            lead_id: checkEmailResult?.pending_lead_data?.id,
        };
        setLoading(true);

        dispatch(login(loginBody)).then((response) => {
            setLoading(false);
            if (response?.payload.status === 422) {
                setError('password', {
                    type: 'api_error',
                    message: response.payload.data?.errors?.password?.[0],
                });
                return;
            }
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const forgotPassword = () => {
        dispatch(setStepId(APP_CONFIG.steps.RECOVER_PASSWORD));
    };

    return (
        <Fade in>
            <Box className='container'>
                <Box className={styles.copy}>
                    <Typography variant='body'>
                        Pentru a continua înregistrarea, te rugăm să introduci parola contului tău de pe{' '}
                        <Link href='https://www.iqos.ro'>www.iqos.ro</Link>:
                    </Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className={styles.password}>
                        <Controller
                            name='password'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    error={!!errors?.password}
                                    helperText={errors?.password?.message}
                                    id='outlined-error'
                                    label='Parolă'
                                    className={styles.passwordInputField}
                                    InputProps={{
                                        type: showPassword ? 'text' : 'password',
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                />
                            )}
                            defaultValue={''}
                        />
                    </Box>

                    <Box className={styles.forgotPassword} onClick={forgotPassword}>
                        <span>Ai uitat parola?</span>
                        <KeyboardBackspaceRounded />
                    </Box>

                    <Box className={styles.nextButton}>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            loading={loading}
                            disabled={Object.keys(dirtyFields).length !== 1}
                        >
                            Pasul următor
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
        </Fade>
    );
};
