import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import axiosInstance, { errorHandler } from '../helpers/axios';

const initialState = {
    results: [],
    pager: {},
    loading: false,
    counties: [],
    cities: [],
};

/**
 * values: {
 *      search: string;
 *      pager: {
 *           page: number;
 *           per_page: number;
 *      }
 * }
 */
export const searchShops = createAsyncThunk('/redemption-shops/search', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get('/redemption-shops/search', { params: values });
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const getCounties = createAsyncThunk('/redemption-shops/counties', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get('/redemption-shops/counties');
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const getCities = createAsyncThunk('/redemption-shops/cities', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get('/redemption-shops/cities');
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

const shops = createSlice({
    name: 'shops',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchShops.pending, (state) => {
                state.loading = true;
            })
            .addCase(searchShops.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.pager = payload.pager;
                state.results = payload.data;
            })
            .addCase(searchShops.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getCounties.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCounties.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.counties = payload;
            })
            .addCase(getCounties.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getCities.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCities.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.cities = payload;
            })
            .addCase(getCities.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { resetState } = shops.actions;

export default shops.reducer;
