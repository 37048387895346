import { Box } from '@mui/system';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, Fade, Link } from '@mui/material';

import { APP_CONFIG } from '../../helpers/config';
import { downloadRegulation, setStepId } from '../../redux/form';

import styles from './WelcomeRegistration.module.scss';
import { LoadingButton } from '@mui/lab';

const footerList = [
    '*www.iqos.ro/regulament-promo-kit-parteneri',
    '**www.iqos.ro/termeni-si-conditii-iqos-care-plus',
    '***Valabilă la primul dispozitiv și condiționată de activarea contului și acceptarea acordului de marketing',
];

export const WelcomeRegistration = () => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(setStepId(APP_CONFIG.steps.PRODUCT_SELECTION));
    };

    const downloadFile = () => {
        dispatch(downloadRegulation());
    };

    return (
        <Fade in>
            <Box className={styles.wrapper}>
                <Box className={styles.header}>
                    <Typography variant='h2'>Bine ai venit!</Typography>
                </Box>
                <Box className={classnames('container', styles.container)}>
                    <Typography variant='h3'>Înregistrează-ți noul dispozitiv și primești gratuit:</Typography>
                    <Box className={styles.list}>
                        <ul>
                            <li>
                                <Typography variant='body'>Pachete de rezerve*</Typography>
                            </li>
                            <li>
                                <Typography variant='body'>Garanție Care Plus** pentru dispozitivul IQOS/lil</Typography>
                            </li>
                            <li>
                                <Typography variant='body'>
                                    Consultanță personală de la reprezentantul IQOS/lil***
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='body'>
                                    IQOS Club, programul de loialitate cu surprize și concursuri
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                </Box>
                <Box className={styles.center}>
                    <Typography variant='body' sx={{ mt: 3 }}>
                        <Link component='button' sx={{ fontSize: 16 }} onClick={downloadFile}>
                            Vezi regulamentul campaniei
                        </Link>
                    </Typography>
                </Box>
                <Box className={`${styles.list} ${styles.footerList}`}>
                    <ul>
                        {footerList.map((item, idx) => (
                            <li key={`welcome-footer-list--${idx}`}>
                                <Typography variant='body'>{item}</Typography>
                            </li>
                        ))}
                    </ul>
                </Box>
                <Box className={styles.nextButton}>
                    <LoadingButton sx={{ width: '100%' }} variant='contained' type='submit' onClick={onClick}>
                        Pasul următor
                    </LoadingButton>
                </Box>
            </Box>
        </Fade>
    );
};
