import React, { useRef, useState } from 'react'
import { Box } from '@mui/system';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, Fade } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { APP_CONFIG } from '../../helpers/config';
import { setStepId, goBack } from '../../redux/form';
import LilVideo from '../../assets/videos/lil_video.mp4';

import styles from './CheckVideo.module.scss';

export const CheckVideo = () => {
    const videoRef = useRef()
    const [play, setPlay] = useState(false)
    const dispatch = useDispatch();

    const onLoginClick = () => {
        dispatch(setStepId(APP_CONFIG.steps.CHECK_EMAIL));
    };
    const onBackClick = () => {
      dispatch(goBack());
    };

    const handleVideo = () => {
      setPlay(true)
      videoRef.current.play();
    }

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile, styles.containerBackground)}>
                <Box className={styles.copy}>
                    <Typography variant='body' sx={{ mb: 2 }}>
                        Vezi mai jos pașii necesari pentru a-ți înregistra dispozitivul:
                    </Typography>
                    <div className={styles.videoContainer}>
                      { !play && <span tabIndex="-1" onClick={handleVideo} className={styles.videoPlaceholder} /> }
                      <video ref={videoRef} width='640' controls>
                          <source src={LilVideo} type='video/mp4' />
                      </video>
                    </div>
                </Box>

                <Box className={styles.nextButton}>
                    <LoadingButton
                        variant='contained'
                        type='submit'
                        onClick={onLoginClick}
                    >
                        Pasul următor
                    </LoadingButton>
                    
                    <LoadingButton
                        variant='contained'
                        type='submit'
                        onClick={onBackClick}
                    >
                        Mergi înapoi
                    </LoadingButton>
                </Box>
            </Box>
        </Fade>
    );
};
