// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecoverPassword_copy__1F4V- {\n  margin-bottom: 40px;\n}\n.RecoverPassword_copy__1F4V- .RecoverPassword_title__rAsR3 {\n  margin-bottom: 40px;\n}\n\n.RecoverPassword_formItem__1FrzT {\n  margin-bottom: 40px;\n}\n\n.RecoverPassword_nextButton__1BtM1 {\n  display: flex;\n  justify-content: center;\n}\n\n@media only screen and (max-width: 432px) {\n  .RecoverPassword_customContainerMobile__Eee-v {\n    padding-top: 16px;\n  }\n\n  .RecoverPassword_nextButton__1BtM1 > button {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/RecoverPassword/RecoverPassword.module.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;AADJ;AAGI;EACI,mBAAA;AADR;;AAKA;EACI,mBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,uBAAA;AAFJ;;AAKA;EACI;IACI,iBAAA;EAFN;;EAKE;IACI,WAAA;EAFN;AACF","sourcesContent":["@import '../../assets/scss/variables';\n\n.copy {\n    margin-bottom: #{$default-spacing-unit * 5};\n\n    .title {\n        margin-bottom: #{$default-spacing-unit * 5};\n    }\n}\n\n.formItem {\n    margin-bottom: #{$default-spacing-unit * 5};\n}\n\n.nextButton {\n    display: flex;\n    justify-content: center;\n}\n\n@media only screen and (max-width: $mobile-breakpoint) {\n    .customContainerMobile {\n        padding-top: #{$default-spacing-unit * 2};\n    }\n\n    .nextButton > button {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy": "RecoverPassword_copy__1F4V-",
	"title": "RecoverPassword_title__rAsR3",
	"formItem": "RecoverPassword_formItem__1FrzT",
	"nextButton": "RecoverPassword_nextButton__1BtM1",
	"customContainerMobile": "RecoverPassword_customContainerMobile__Eee-v"
};
export default ___CSS_LOADER_EXPORT___;
