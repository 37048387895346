import { Box } from '@mui/system';

import { ShopList } from '../../components/ShopList';

import styles from './ShopListContainer.module.scss';

const ShopListContainer = () => {
    return (
        <Box className={styles.noParent}>
            <ShopList />
        </Box>
    );
};

export default ShopListContainer;
