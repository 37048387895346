import * as yup from 'yup';
import {Box} from '@mui/system';
import classnames from 'classnames';
import {Fade} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {
    TextField,
    Typography,
    Link,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    Checkbox,
    FormHelperText,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import {useForm, Controller, useFormState} from 'react-hook-form';

import {FormHelperDevice} from '../FormHelperDevice';
import {registerLAU, setStoreIqosType, checkPosCode, setPosInfo, validatePhoneNumber} from '../../redux/form';
import {APP_CONFIG, DEFAULT_IQOS_TYPE, IQOS_TYPES, PRODUCT_TYPES} from '../../helpers/config';

import styles from './WelcomeLAU.module.scss';
import {formErrorHandler, isFallbackValidationOn} from '../../helpers/general';
import SnackbarService from '../../helpers/snackbar';

const schemaFields = {
    phone: yup
        .string()
        .trim()
        .transform((v) => v.replace(/\s/g, ''))
        .matches(APP_CONFIG.regexPhoneNumber, APP_CONFIG.validationMessages.invalidPhoneFormat)
        .required(APP_CONFIG.validationMessages.required),
    accessory_code: yup
        .string()
        .matches(APP_CONFIG.regexAccessoryCode, APP_CONFIG.validationMessages.invalidNameFormat)
        .min(APP_CONFIG.minAccessoryCode, APP_CONFIG.validationMessages.min(APP_CONFIG.minAccessoryCode))
        .max(APP_CONFIG.maxAccessoryCode, APP_CONFIG.validationMessages.max(APP_CONFIG.maxAccessoryCode))
        .required(APP_CONFIG.validationMessages.required),
    pos_code: yup
        .string()
        .min(APP_CONFIG.minPosCode, APP_CONFIG.validationMessages.min(APP_CONFIG.minPosCode))
        .max(APP_CONFIG.maxPosCode, APP_CONFIG.validationMessages.max(APP_CONFIG.maxPosCode))
        .required(APP_CONFIG.validationMessages.required),
    marketing_opt_in: yup.bool().required(APP_CONFIG.validationMessages.required),
    terms_and_conditions: yup.bool().oneOf([true], APP_CONFIG.validationMessages.required),
    third_party_delivered: yup.bool().optional(),
};

if (isFallbackValidationOn()) {
    schemaFields.device_code = yup
        .string()
        .matches(APP_CONFIG.regexDeviceCode, APP_CONFIG.validationMessages.invalidNameFormat)
        .min(APP_CONFIG.minDeviceCode, APP_CONFIG.validationMessages.min(APP_CONFIG.minDeviceCode))
        .max(APP_CONFIG.maxDeviceCode, APP_CONFIG.validationMessages.max(APP_CONFIG.maxDeviceCode))
        .required(APP_CONFIG.validationMessages.required);
}
const schema = yup.object(schemaFields).required();

export const WelcomeLAU = () => {
    const dispatch = useDispatch();
    const {
        loginResult,
        checkEmailResult,
        productType,
        posInfo,
        phoneNumberValidation
    } = useSelector((state) => state.form);

    const isIqos = productType === PRODUCT_TYPES.iqos;

    const [loading, setLoading] = useState(false);
    const [iqosType, setIqosType] = useState(DEFAULT_IQOS_TYPE);
    const [disablePosCode, setDisablePosCode] = useState(false);
    const [disablePhone, setDisablePhone] = useState(false);
    const [showPhoneNumberDisclaimer, setShowPhoneNumberDisclaimer] = useState(false);
    const [showPhoneNumberAlreadyValidatedDisclaimer, setShowPhoneNumberAlreadyValidatedDisclaimer] = useState(false);
    const {
        control,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });

    const {dirtyFields} = useFormState({
        control,
    });

    // TODO -> check how the schema was build for the form, 
    // the isValid field from formState is returning false even if all the fields are filled and no errors are returned
    const disableSubmit = Object.values(getValues()).some(fieldValue => !fieldValue) && dirtyFields;

    useEffect(() => {
        if (loginResult.phone) {
            setValue('phone', loginResult.phone, {shouldDirty: true});
        }
    }, [loginResult, setValue]);

    useEffect(() => {
        if (checkEmailResult?.pending_lead_data?.pos_code) {
            handlePosCode(checkEmailResult.pending_lead_data.pos_code);
            setValue('pos_code', checkEmailResult.pending_lead_data.pos_code, {shouldDirty: true});
            setDisablePosCode(true);
        }
        if (checkEmailResult?.pending_lead_data?.phone_number) {
            setValue('phone', checkEmailResult.pending_lead_data.phone_number, {shouldDirty: true});
            dispatch(validatePhoneNumber({
                phone_number: checkEmailResult.pending_lead_data.phone_number
            }));
            setDisablePhone(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkEmailResult, setValue]);


    useEffect(() => {
        if (phoneNumberValidation &&
            phoneNumberValidation.phoneNumberIsAssociatedOnOtherAccounts &&
            phoneNumberValidation.phone_validated === false &&
            checkEmailResult?.pending_lead_data?.phone_number !== loginResult?.phone &&
            checkEmailResult.pending_lead_data.user_type === 'LAU') {
            setShowPhoneNumberDisclaimer(true);
        }
        if (phoneNumberValidation &&
            phoneNumberValidation.phone_validated &&
            checkEmailResult?.pending_lead_data?.phone_number !== loginResult?.phone &&
            checkEmailResult.pending_lead_data.user_type === 'LAU') {
            setShowPhoneNumberAlreadyValidatedDisclaimer(true);
        }

    }, [phoneNumberValidation])

    const onSubmit = (data) => {
        setLoading(true);

        const body = {
            ...data,
            email: checkEmailResult.email,
            lead_id: checkEmailResult?.pending_lead_data?.id,
        };

        dispatch(registerLAU(body)).then((response) => {
            if (response?.payload.status === 422) {
                const errors = response.payload?.data?.errors;
                if (!errors) {
                    return;
                }

                clearErrors();

                formErrorHandler(errors, setError);

                setLoading(false);
                return;
            }
        });
    };

    const handleIqosTypeChange = (event) => {
        setIqosType(event.target.value);
        dispatch(setStoreIqosType(event.target.value));
    };

    const handlePosCode = (value) => {
        if (value?.length === 5) {
            dispatch(checkPosCode({id: value})).then((response) => {
                if (response?.payload.status === 422) {
                    const errors = response.payload?.data?.errors;

                    if (!errors) {
                        return;
                    }

                    clearErrors();

                    formErrorHandler(errors, setError);
                }

                if (response?.meta?.requestStatus === APP_CONFIG.requestStatusFulfilled) {
                    clearErrors('pos_code');
                }
            });
        } else {
            if (posInfo) {
                dispatch(setPosInfo(null));
            }
        }
    };

    const renderDescription = () => {
        if (checkEmailResult.pending_lead_data?.limit_reached) {
            return <p>Completează datele și mergi la pasul următor pentru a beneficia de <b>Garanție</b>.</p>
        } else {
            return  <Typography className={styles.mobileDescription}>
            Completează datele și primești pachete de rezerve. Pentru mai multe detalii consultă regulamentul la{' '}
            <Link
                href='www.iqos.ro/regulament-promo-kit-parteneri'
                color={'#000'}
                variant={'bold'}
            >
                www.iqos.ro/regulament-promo-kit-parteneri
            </Link>.
        </Typography>
        }
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copyContainer}>
                    <Typography variant='body' className={styles.title}>
                        Salut, {loginResult?.firstname} {loginResult?.lastname}!
                    </Typography>

                    <Typography variant='body' className={styles.description}>
                        {renderDescription()}
                    </Typography>

                    <Typography variant='small'>NOTĂ: Toate câmpurile sunt obligatorii.</Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className={styles.formItem}>
                        <Controller
                            name='phone'
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    required
                                    disabled={disablePhone}
                                    error={!!errors.phone}
                                    helperText={errors?.phone?.message}
                                    label='Număr de telefon'
                                    fullWidth
                                />
                            )}
                            defaultValue={''}
                        />
                    </Box>

                    {isIqos ? (
                        <Box className={styles.formItem}>
                            <FormControl fullWidth>
                                <InputLabel id='iqos-type'>Tip dispozitiv</InputLabel>
                                <Select
                                    labelId='iqos-type'
                                    id='iqos-tpye'
                                    value={iqosType}
                                    label='Tip dispozitiv'
                                    onChange={handleIqosTypeChange}
                                >
                                    {IQOS_TYPES.map(({label, value}) => (
                                        <MenuItem key={value.replace(/\s+/gi, '-').toLocaleLowerCase()} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    ) : null}

                    <Box className={styles.formItem}>
                        <Controller
                            name='accessory_code'
                            control={control}
                            render={({field: {onChange, ...rest}}) => (
                                <>
                                    <TextField
                                        {...rest}
                                        required
                                        error={!!errors.accessory_code}
                                        helperText={errors?.accessory_code?.message}
                                        onChange={(ev) => {
                                            const value = ev?.target?.value?.replace(/\s+/gi, '');
                                            onChange(value);
                                        }}
                                        label='Cod componentă'
                                        fullWidth
                                        aria-describedby='cod-componentă'
                                    />
                                    <FormHelperDevice
                                        type={APP_CONFIG.accessoryId}
                                        id='cod-componentă'
                                        copy={'Unde găsesc codul componentei?'}
                                    />
                                </>
                            )}
                            defaultValue={''}
                        />
                    </Box>

                    <Box className={styles.formItem}>
                        <Controller
                            name='pos_code'
                            control={control}
                            render={({field}) => (
                                <>
                                    <TextField
                                        {...field}
                                        required
                                        error={!!errors.pos_code}
                                        helperText={errors?.pos_code?.message}
                                        label='Cod magazin'
                                        fullWidth
                                        aria-describedby='cod-magazin'
                                        disabled={disablePosCode}
                                        onChange={(e) => {
                                            handlePosCode(e?.target?.value);
                                            field?.onChange?.(e);

                                            return e;
                                        }}
                                    />
                                    <FormHelperDevice
                                        type={APP_CONFIG.shopCodeId}
                                        id='cod-magazin'
                                        copy={'Unde găsesc codul magazinului?'}
                                    />
                                </>
                            )}
                            defaultValue={''}
                        />
                    </Box>

                    {isFallbackValidationOn() ? (
                        <Box className={styles.formItem}>
                            <Controller
                                name='device_code'
                                control={control}
                                render={({field}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            required
                                            error={!!errors.device_code}
                                            helperText={errors?.device_code?.message}
                                            label='Cod dispozitiv'
                                            fullWidth
                                            aria-describedby='cod-dispozitiv'
                                        />
                                        <FormHelperDevice
                                            type={APP_CONFIG.deviceCodeId}
                                            id='cod-dispozitiv'
                                            copy={'Unde găsesc codul dispozitivului?'}
                                        />
                                    </>
                                )}
                                defaultValue={''}
                            />
                        </Box>
                    ) : null}

                    {/* Hide Glovo as per - PTQR-541 */}
                    {/* {posInfo?.parent && posInfo?.parent !== null ? (
                        <Box className={styles.formItem}>
                            <Controller
                                name='third_party_delivered'
                                control={control}
                                render={({field}) => (
                                    <Box className={styles.formItemRadio}>
                                        <FormLabel component='legend' className={styles.label}>
                                            <Typography>Achiziționat prin Glovo?</Typography>
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label='third_party_delivered'
                                            name='third_party_delivered'
                                            {...field}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio/>}
                                                label='Da'
                                                className={styles.radioGroup}
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio/>}
                                                label='Nu'
                                                className={styles.radioGroup}
                                            />
                                        </RadioGroup>
                                    </Box>
                                )}
                                defaultValue={null}
                            />
                        </Box>
                    ) : null} */}

                    <Box className={styles.formItem}>
                        <Controller
                            name='marketing_opt_in'
                            control={control}
                            render={({field}) => (
                                <Box className={styles.formItemRadio}>
                                    <FormLabel component='legend' className={styles.label}>
                                        Sunt de acord să primesc prin e-mail sau SMS informații comerciale despre IQOS,
                                        HEETS, TEREA, LEVIA, lil Solid, FIIT sau alte produse care nu implică arderea tutunului şi
                                        servicii conexe acestor produse, oferite de Philip Morris Trading S.R.L sau de
                                        partenerii săi.
                                        <Typography component='div'>
                                            <Typography
                                                component='p'
                                                variant='bold'
                                                className={styles.marketingBenefits}
                                            >
                                                Doresc să am acces la beneficii:
                                            </Typography>
                                            <ul>
                                                <li>ofertele exclusive</li>
                                                <li>reduceri</li>
                                                <li>vouchere</li>
                                            </ul>
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup row aria-label='marketing_opt_in' name='marketing_opt_in' {...field}>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio/>}
                                            label='Da'
                                            className={styles.radioGroup}
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio/>}
                                            label='Nu'
                                            className={styles.radioGroup}
                                        />
                                    </RadioGroup>
                                </Box>
                            )}
                            defaultValue={null}
                        />
                    </Box>
                    {
                        showPhoneNumberDisclaimer &&
                        <Box>
                            <FormLabel component='legend' className={styles.phoneNumberDisclaimer}>
                                Acest număr de telefon va fi asociat acestui cont, în cazul în care nu este validat pe
                                un cont deja existent.
                            </FormLabel>
                        </Box>
                    }
                    <Box className={classnames(styles.formItem, styles.terms_and_conditions)}>
                        <Controller
                            name='terms_and_conditions'
                            control={control}
                            render={({field}) => (
                                <FormControl error={!!errors.terms_and_conditions}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        labelPlacement={'end'}
                                        className={styles.terms_and_conditions}
                                        label={
                                            <Typography>
                                                *Am citit și înțeles{' '}
                                                <Link
                                                    href='https://www.pmiprivacy.com/ro/consumer'
                                                    color={'#000'}
                                                    variant={'bold'}
                                                >
                                                    Notificarea de protecție a datelor cu caracter personal
                                                </Link>
                                            </Typography>
                                        }
                                    />
                                    <FormHelperText>{errors?.terms_and_conditions?.message}</FormHelperText>
                                </FormControl>
                            )}
                            defaultValue={false}
                        />
                    </Box>
                    {
                        showPhoneNumberAlreadyValidatedDisclaimer &&
                        <Box>
                            <FormLabel component='legend'
                                       className={classnames(styles.phoneNumberDisclaimer, styles.error)}>
                                Numărul de telefon furnizat este validat pe un cont deja existent. Vă rugăm să
                                introduceți un alt număr de telefon sau să ne contactați la <a href={"tel:0800030333"}>0800030333</a>.</FormLabel>
                        </Box>
                    }
                    <Box className={styles.nextButton}>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            loading={loading}
                            disabled={disableSubmit || showPhoneNumberAlreadyValidatedDisclaimer}
                        >
                            Pasul următor
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
        </Fade>
    );
};
