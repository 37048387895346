import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Fade, Typography, Link } from '@mui/material';

import { APP_CONFIG } from '../../helpers/config';
import SnackbarService from '../../helpers/snackbar';
import { resendActivateDeviceEmail } from '../../redux/form';

import styles from './ActivateAccount.module.scss';
import { handleAmountOfReward } from '../../helpers/general';

export const ActivateAccount = () => {
    const dispatch = useDispatch();

    const { registerLASResult, checkEmailResult } = useSelector((state) => state.form);

    const sku_model = registerLASResult?.sku_model || checkEmailResult?.pending_lead_data?.sku_model 

    const resendEmail = () => {
        const dcsId = registerLASResult?.dcs_id || checkEmailResult?.dcs_id 

        dispatch(resendActivateDeviceEmail({ dcs_id: dcsId })).then((response) => {
            if (response?.meta?.requestStatus === APP_CONFIG.requestStatusFulfilled) {
                SnackbarService.toast('S-a retrimis un e-mail de activare');
                return;
            }
            SnackbarService.toast('S-a întâmplat ceva, încearcă din nou.');
        });
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copy}>
                    <Typography variant='body'>
                        Mai ai doar un pas! Verifică-ți e-mail-ul, urmează instrucțiunile primite și activează-ți contul
                        pe <Link href='https://www.iqos.ro'>www.iqos.ro</Link>. După activare, vei primi un cod pentru {handleAmountOfReward(sku_model)} pachete gratuite.
                    </Typography>
                    <Typography variant='small'>
                        *beneficiul va fi confirmat de catre noi printr-un SMS in termen de maxim 24h
                    </Typography>
                </Box>

                {/* <Button variant='contained' onClick={resendEmail} fullWidth>
                    Retrimite emailul de activare
                </Button> */}
            </Box>
        </Fade>
    );
};
