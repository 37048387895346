import { Box } from '@mui/system';
import classnames from 'classnames';
import { Typography, Fade, Link } from '@mui/material';

import { FormHelperDevice } from '../../components/FormHelperDevice';

import { APP_CONFIG } from '../../helpers/config';
import styles from './UnavailableApp.module.scss';

const UnavailableApp = () => {
    return (
        <Fade in>
            <Box className={classnames(styles.parent)}>
                <Box className={classnames('container', styles.customContainerMobile)}>
                    <Box className={styles.copy}>
                        <Typography variant='body'>
                            Ne pare rău, dar momentan înregistrarea online nu este disponibilă.
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant='body'>
                            Te rugăm trimite prin SMS gratuit la{' '}
                            <a className={styles.sms} href='sms:3768'>
                                3768
                            </a>{' '}
                            <Typography variant='bold'>codul unic al magazinului</Typography> înscris pe kit-ul
                            achiziționat, iar un consultant te va apela în maximum 24 de ore pentru înregistrarea
                            dispozitivului și pentru a beneficia de pachete de rezerve gratuite sau de un
                            discount în contul tău din <Link href='https://www.iqos.ro'>www.iqos.ro</Link>.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <FormHelperDevice
                            type={APP_CONFIG.shopCodeId}
                            id='cod-magazin'
                            copy={'Unde găsesc codul magazinului?'}
                        />
                    </Box>
                </Box>
            </Box>
        </Fade>
    );
};

export default UnavailableApp;
